/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "첫 번째 로그에서는 루비 25주년, 장애 이야기, GitHub Actions 등에 대한 이야기를 했어요."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://status.github.com/messages/2018-10-22"
  }, "GitHub"), ", ", React.createElement(_components.a, {
    href: "http://www.bloter.net/archives/322208"
  }, "YouTube 장애")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/yyall_/status/1052902681008689153"
  }, "유튜브 터진 이유 - 네이버 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/features/actions"
  }, "GitHub Actions 발표")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/mitchellh/status/1052258881848659969"
  }, "GitHub Actions에서 HCL을 사용하는것에 대한 하시모토 트윗")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://25.ruby.or.jp/"
  }, "(일본어) Ruby25")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://githut.info/"
  }, "GitHunt - 개발언어별 GitHub 통계")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/yukihiro_matz/status/936014306650361856"
  }, "루비를 만들기 시작했을때 24살의 마츠 사진")), "\n", React.createElement(_components.li, null, "카카오 카풀 & ", React.createElement(_components.a, {
    href: "https://brunch.co.kr/@kakaomobility/19"
  }, "카카오 모빌리티 레포트 2018")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/newsroom/2018/10/adobe-previews-photoshop-cc-on-ipad-and-new-apps-for-creative-pros/"
  }, "포토샵 CC for iPad")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pc.watch.impress.co.jp/docs/column/ubiq/1148805.html"
  }, "포토샵 CC의 클라우드 PSD 언급된 인터뷰 - 일본어")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://brunch.co.kr/@forchoon/221"
  }, "한글날 배포된 무료폰트들")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hashicorp.com/brand"
  }, "HashiCorp 브랜드 페이지")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tumblbug.com/soulfont"
  }, "한글 컬러폰트 색동저고리체")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.clien.net/service/board/park/12704049"
  }, "색동 저고리체 개발이야기 - Clien")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.engadget.com/2018/10/16/the-kindle-paperwhite-is-ready-for-the-bath/"
  }, "방수기능의 킨들 페이퍼화이트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.v.daum.net/v/20181018172139856"
  }, "라인메신저 소액투자")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://qiita.com/kotakanbe@github/items/32cf4eb3de1741af26fb"
  }, "mohikan slack 소개 - 일본어")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.zdnet.co.kr/news/news_view.asp?artice_id=20181004091632"
  }, "팟프리카 - 아프리카TV 팟캐스트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://outstanding.kr/podcast20180718/"
  }, "A급 제작자가 이야기하는 팟캐스트의 현실과 고민")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
